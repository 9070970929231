import React from "react";

import './privacy-policy.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <h1>PRIVACY POLICY</h1>

            <p>Last updated July 06, 2022</p>

            <p>
                This privacy notice for Frontline Youth Network ('Company',
                'we', 'us', or 'our',), describes how and why we might collect,
                store, use, and/or share ('process') your information when you
                use our services ('Services'), such as when you: Download and
                use our mobile application (Peace ON), or any other application
                of ours that links to this privacy notice Engage with us in
                other related ways, including any sales, marketing, or events
                Questions or concerns? Reading this privacy notice will help you
                understand your privacy rights and choices. If you do not agree
                with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us
                at fynarmenia@gmail.com.
            </p>

            <h2>SUMMARY OF KEY POINTS</h2>

            <p>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for. You
                can also click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#toc" target="_blank">here</a> to go directly to our table of contents.
            </p>

            <p>
                What personal information do we process? When you visit, use, or
                navigate our Services, we may process personal information
                depending on how you interact with Frontline Youth Network and
                the Services, the choices you make, and the products and
                features you use. Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#personalinfo" target="_blank">here</a> to learn more.
            </p>

            <p>
                Do we process any sensitive personal information? We do not
                process sensitive personal information.
            </p>

            <p>
                Do we receive any information from third parties? We do not
                receive any information from third parties.
            </p>

            <p>
                How do we process your information? We process your information
                to provide, improve, and administer our Services, communicate
                with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes
                with your consent. We process your information only when we have
                a valid legal reason to do so. Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#infouse" target="_blank">here</a> to learn more.
            </p>

            <p>
                In what situations and with which parties do we share personal
                information? We may share information in specific situations and
                with specific third parties. Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#whoshare" target="_blank">here</a> to learn more.
            </p>

            <p>
                How do we keep your information safe? We have organisational and
                technical processes and procedures in place to protect your
                personal information. However, no electronic transmission over
                the internet or information storage technology can be guaranteed
                to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other unauthorised third parties
                will not be able to defeat our security and improperly collect,
                access, steal, or modify your information. Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#infosafe" target="_blank">here</a> to learn
                more.
            </p>

            <p>
                What are your rights? Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information. Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#privacyrights" target="_blank">here</a>
                to learn more.
            </p>

            <p>
                How do you exercise your rights? The easiest way to exercise
                your rights is by filling out our data subject request form
                available <a rel="noreferrer" href="https://app.termly.io/notify/4f6bcdc1-1234-465e-8248-f77ea858e39e" target="_blank">here</a>, or by contacting us. We will consider and act
                upon any request in accordance with applicable data protection
                laws.
            </p>

            <p>
                Want to learn more about what Frontline Youth Network does with
                any information we collect? Click <a rel="noreferrer" href="https://app.termly.io/dashboard/website/af36288b-2209-4c66-9a31-daea3b74dfc0#toc" target="_blank">here</a> to review the notice in
                full.
            </p>

            <h2>TABLE OF CONTENTS</h2>

            <ol>
                <li>WHAT INFORMATION DO WE COLLECT?</li>
                <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                <li>
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </li>
                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li>
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                </li>
            </ol>

            <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>

            <p>Personal information you disclose to us</p>

            <p>
                In Short: We collect personal information that you provide to
                us.
            </p>

            <p>
                We collect personal information that you voluntarily provide to
                us when you express an interest in obtaining information about
                us or our products and Services, when you participate in
                activities on the Services, or otherwise when you contact us.
            </p>

            <p>
                Personal Information Provided by You. The personal information
                that we collect depends on the context of your interactions with
                us and the Services, the choices you make, and the products and
                features you use. The personal information we collect may
                include the following: email addresses usernames passwords
            </p>
            <p>
                Sensitive Information. We do not process sensitive information.
            </p>

            <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
            </p>

            <p>Information automatically collected</p>

            <p>
                In Short: Some information — such as your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected
                automatically when you visit our Services.
            </p>

            <p>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
            </p>

            <p>
                The information we collect includes: Log and Usage Data. Log and
                usage data is service-related, diagnostic, usage, and
                performance information our servers automatically collect when
                you access or use our Services and which we record in log files.
                Depending on how you interact with us, this log data may include
                your IP address, device information, browser type, and settings
                and information about your activity in the Services (such as the
                date/time stamps associated with your usage, pages and files
                viewed, searches, and other actions you take such as which
                features you use), device event information (such as system
                activity, error reports (sometimes called 'crash dumps'), and
                hardware settings). Device Data. We collect device data such as
                information about your computer, phone, tablet, or other device
                you use to access the Services. Depending on the device used,
                this device data may include information such as your IP address
                (or proxy server), device and application identification
                numbers, location, browser type, hardware model, Internet
                service provider and/or mobile carrier, operating system, and
                system configuration information. Location Data. We collect
                location data such as information about your device's location,
                which can be either precise or imprecise. How much information
                we collect depends on the type and settings of the device you
                use to access the Services. For example, we may use GPS and
                other technologies to collect geolocation data that tells us
                your current location (based on your IP address). You can opt
                out of allowing us to collect this information either by
                refusing access to the information or by disabling your Location
                setting on your device. However, if you choose to opt out, you
                may not be able to use certain aspects of the Services.
            </p>
            <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>

            <p>
                In Short: We process your information to provide, improve, and
                administer our Services, communicate with you, for security and
                fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.
            </p>

            <p>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including: To
                deliver and facilitate delivery of services to the user. We may
                process your information to provide you with the requested
                service. To respond to user inquiries/offer support to users. We
                may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service. To send administrative information to you. We may
                process your information to send you details about our products
                and services, changes to our terms and policies, and other
                similar information. To request feedback. We may process your
                information when necessary to request feedback and to contact
                you about your use of our Services. To send you marketing and
                promotional communications. We may process the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. You can opt
                out of our marketing emails at any time. For more information,
                see 'WHAT ARE YOUR PRIVACY RIGHTS?' below). To deliver targeted
                advertising to you. We may process your information to develop
                and display personalised content and advertising tailored to
                your interests, location, and more. To evaluate and improve our
                Services, products, marketing, and your experience. We may
                process your information when we believe it is necessary to
                identify usage trends, determine the effectiveness of our
                promotional campaigns, and to evaluate and improve our Services,
                products, marketing, and your experience. To identify usage
                trends. We may process information about how you use our
                Services to better understand how they are being used so we can
                improve them. To determine the effectiveness of our marketing
                and promotional campaigns. We may process your information to
                better understand how to provide marketing and promotional
                campaigns that are most relevant to you. To comply with our
                legal obligations. We may process your information to comply
                with our legal obligations, respond to legal requests, and
                exercise, establish, or defend our legal rights.
            </p>

            <h3>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h3>

            <p>
                In Short: We may share information in specific situations
                described in this section and/or with the following third
                parties.
            </p>

            <p>
                We may need to share your personal information in the following
                situations: Business Transfers. We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
            </p>

            <h3>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>

            <p>
                In Short: We keep your information for as long as necessary to
                fulfil the purposes outlined in this privacy notice unless
                otherwise required by law.
            </p>

            <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than 1 year.
            </p>

            <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymise such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
            </p>

            <h3>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>

            <p>
                In Short: We aim to protect your personal information through a
                system of organisational and technical security measures.
            </p>

            <p>
                We have implemented appropriate and reasonable technical and
                organisational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
            </p>

            <h3>6. DO WE COLLECT INFORMATION FROM MINORS?</h3>

            <p>
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
            </p>

            <p>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                touch@frontlineyouth.net.
            </p>

            <h3>7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>

            <p>
                In Short: You may review, change, or terminate your account at
                any time.
            </p>

            <p>
                If you are located in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your local data protection supervisory
                authority. You can find their contact details here:
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>

            <p>
                If you are located in Switzerland, the contact details for the
                data protection authorities are available here:
                https://www.edoeb.admin.ch/edoeb/en/home.html.
            </p>

            <p>
                Withdrawing your consent: If we are relying on your consent to
                process your personal information, which may be express and/or
                implied consent depending on the applicable law, you have the
                right to withdraw your consent at any time. You can withdraw
                your consent at any time by contacting us by using the contact
                details provided in the section 'HOW CAN YOU CONTACT US ABOUT
                THIS NOTICE?' below.
            </p>

            <p>
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal, nor when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
            </p>

            <p>
                Opting out of marketing and promotional communications: You can
                unsubscribe from our marketing and promotional communications at
                any time by clicking on the unsubscribe link in the emails that
                we send, or by contacting us using the details provided in the
                section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You
                will then be removed from the marketing lists. However, we may
                still communicate with you — for example, to send you
                service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
            </p>

            <p>
                If you have questions or comments about your privacy rights, you
                may email us at info@frontlineyouth.net.
            </p>

            <h3>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>

            <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ('DNT') feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
            </p>

            <h3>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>

            <p>
                In Short: Yes, if you are a resident of California, you are
                granted specific rights regarding access to your personal
                information.
            </p>

            <p>
                California Civil Code Section 1798.83, also known as the 'Shine
                The Light' law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
            </p>

            <p>
                If you are under 18 years of age, reside in California, and have
                a registered account with Services, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).
            </p>

            <h3>10. DO WE MAKE UPDATES TO THIS NOTICE?</h3>

            <p>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
            </p>

            <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated 'Revised' date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
            </p>

            <h3>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>

            <p>
                If you have questions or comments about this notice, you may
                email us at touch@frontlineyouth.net or by post to:
            </p>

            <p>
                Frontline Youth Network Alikhanyan 12 Berd, Tavush 4201 Armenia
            </p>

            <h3>
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
            </h3>

            <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please
                submit a request form by clicking <a rel="noreferrer" href="https://app.termly.io/notify/4f6bcdc1-1234-465e-8248-f77ea858e39e" target="_blank">here</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
