import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OutletType } from './dashboard';
import useAuth from '../hooks/use-auth.hook';
import { CategoryService } from '../services/category.service';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Category: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { setTitle } = useOutletContext<OutletType>();
    const [category_title, setCategoryTitle] = useState('');
    const auth = useAuth();

    async function saveCategoryHandler(){
        if(category_title !== ''){
            const result = await CategoryService.edit(auth.token, +params.id!, category_title);
            if(result.success){
                navigate(-1);
            }
        }
    }

    useEffect(() => {
        setTitle('Գլուխներ');

        CategoryService.get(auth.token, params.id!).then((res) => {
            if(res.title){
                setCategoryTitle(res.title);
            }
        });
    }, []);

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField label="Գլխի անվանումը" value={category_title} onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => setCategoryTitle(event.currentTarget.value)
                                } variant="standard" required />
                                <Button variant="contained" onClick={saveCategoryHandler}>Պահպանել</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
};

export default Category;
