import { Navigate } from "react-router-dom";
import useAuth from "../hooks/use-auth.hook";

const ProtectedRoute: React.FC<{children: JSX.Element}> = (props) => {
    const auth = useAuth();
    if(!auth?.token){
        return <Navigate to="/" replace />;
    }
    return props.children;
}

export default ProtectedRoute;