import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import { AuthService } from '../services/auth.service';

export interface UserStruct{
    full_name?: string,
    id?: number,
    physician_id?: number
}

const AuthProvider: React.FC<{children: JSX.Element}> = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [access_token, setAccessToken] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(token){
            AuthService.check(token).then((result) => {
                if(!result || !result.success){
                    storeTokens('');
                    navigate('/', {replace: true});
                }
                else{
                    storeTokens(token);
                }
                setLoaded(true);
            });
        }
        else{
            setLoaded(true);
        }
    }, [access_token]);


    const handleLogin = useCallback(async function (email: string, password: string, event: React.FormEvent) {
        event.preventDefault();
        const result = await AuthService.login(email, password);
        if(result && result.access_token){
            storeTokens(result.access_token);
            navigate('/dashboard', {replace: true});
        }
    }, []);

    const handleLogout = useCallback(function (){
        setAccessToken('');
        localStorage.removeItem('token');
        navigate('/', {replace: true});
    }, []);

    const storeTokens = useCallback(function (token: string){
        setAccessToken(token);
        localStorage.setItem('token', token);
    }, []);


    if(!loaded){
        return <></>;
    }

    return (
        <AuthContext.Provider value={{
            token: access_token,
            onLogin: handleLogin,
            onLogout: handleLogout
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;