import axios, { AxiosError, AxiosResponse } from 'axios';

export class UserService{
    static async getAll(token: string): Promise<any[] | any>{
        let res;
        try{ res = await axios.get('api/users', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }

    static async getOne(token: string, id: number): Promise<any>{
        let res;
        try{ res = await axios.get(`api/users/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }

    static async create(token: string, email: string, name: string, password: string, admin: boolean): Promise<any>{
        let res;
        try{ res = await axios.post('api/users', {
            email,
            name,
            password,
            admin
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }

    static async edit(
        token: string,
        id: number,
        props: {
            email?: string,
            email_verif?: boolean,
            name?: string,
            password?: string,
            admin?: boolean
        }
    ): Promise<any>{
        let res;
        try{ res = await axios.patch(`api/users/${id}`, {
            ...props
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }

    static async remove(token: string, id: number): Promise<any>{
        let res;
        try{ res = await axios.delete(`api/users/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }
}