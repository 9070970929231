import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { OutletType } from './dashboard';
import { CategoryService } from '../services/category.service';
import useAuth from '../hooks/use-auth.hook';
import { Link } from 'react-router-dom';


const Categories: React.FC = () => {
    const { setTitle } = useOutletContext<OutletType>();
    const [categories, setCategories] = useState([{id: 0, title:''}]);
    const [cat_title, setCatTitle] = useState('');
    const auth = useAuth();

    useEffect(() => {
        CategoryService.getAll(auth.token).then((res) => {
            setCategories(res);
        });
    }, []);

    useEffect(() => {
        setTitle('Գլուխներ');
    }, [setTitle]);

    function onCatTitleChange(event: React.ChangeEvent<HTMLInputElement>){
        setCatTitle(event.currentTarget.value);
    }

    async function addClickHandler(){
        if(cat_title === ''){
            return;
        }
        const result = await CategoryService.addCategory(auth.token, cat_title);
        if(result.success){
            const res = await CategoryService.getAll(auth.token);
            setCategories(res);
        }
    }

    async function removeClickHandler(id: number){
        const result = await CategoryService.removeCategory(auth.token, id);
        if(result.success){
            setCategories(categories.filter((item) => item.id !== id));
        }
    }

    return <div>
        <div className="wrapper">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Stack spacing={1}>
                        <TextField label="Գլխի անվանումը" variant="standard" value={cat_title} required onChange={onCatTitleChange} />
                        <Button variant="contained" onClick={addClickHandler}>Ավելացնել</Button>
                    </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <List>
                            { categories.map((item) => 
                            <ListItem sx={{'paddingRight': '70px'}} key={item.id} secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="edit">
                                        <Link to={item.id.toString()}><EditIcon /></Link>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={removeClickHandler.bind(null, item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                                
                              }>
                                <ListItemText
                                    primary={item.title}
                                    secondary={null ? 'Secondary text' : null}
                                />
                            </ListItem>) }
                            
                        </List>
                    </Grid>
                </Grid>
            </Box>

        </div>
    </div>;
}

export default Categories;