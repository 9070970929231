import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { OutletType } from './dashboard';
import { TheoryService } from '../services/theory.service';
import { CategoryService } from '../services/category.service';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/use-auth.hook';

export type TheoryStruct = {
    id: number,
    title: string,
    body: string,
    order: number,
    category: {
        id: number,
        title: string,
    }
};

const Theories: React.FC = () => {
    const { setTitle } = useOutletContext<OutletType>();
    const [theories, setTheories] = useState<TheoryStruct[]>([]);
    const [categories, setCategories] = useState<{title: string, id: number}[]>([]);
    const [theory_title, setTheoryTitle] = useState('');
    const [body, setBody] = useState('');
    const [selected_category, setSelectedCategory] = useState('');
    const auth = useAuth();

    useEffect(() => {
        CategoryService.getAll(auth.token).then((res) => {
            setCategories(res);
        });
        TheoryService.getAll(auth.token).then((res) => {
            setTheories(res);
        });
    }, []);

    useEffect(() => {
        setTitle('Թեմաներ');
        
    }, [setTitle]);

    function selectCategoryChange(event: SelectChangeEvent) {
        setSelectedCategory(event.target.value);
    }

    async function removeClickHandler(id: number) {
        const res = await TheoryService.remove(auth.token, id);
        if(res.success){
            setTheories(theories.filter((item) => item.id !== id));
        }
    }

    async function addClickHandler() {
        if(theory_title !== '' && body !== '' && selected_category !== ''){
            const res = await TheoryService.insert(auth.token, theory_title, body, +selected_category);
            if(res.success){
                setTheories((prev) => {
                    const arr = [res.theory].concat(prev);
                    arr.sort((a, b) => {
                        return Math.sign(a.order - b.order);
                    })
                    return arr;
                });
                setTheoryTitle('');
                setBody('');
                setSelectedCategory('');
            }
        }
    }

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField label="Թեմայի անվանումը" value={theory_title} onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => setTheoryTitle(event.currentTarget.value)
                                } variant="standard" required />
                                <TextField
                                    id="outlined-textarea"
                                    label="Տեքստ"
                                    placeholder="Տեքստ"
                                    value={body}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBody(event.currentTarget.value)}
                                    required
                                    multiline
                                />
                                <FormControl required fullWidth>
                                    <InputLabel id="category-select-label">Ընտրել Գլուխը</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="demo-simple-select"
                                        value={selected_category}
                                        label="Ընտրել Գլուխը"
                                        onChange={selectCategoryChange}
                                    >
                                        {
                                            categories.map((item) => {
                                                return <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Button variant="contained" onClick={addClickHandler}>Ավելացնել</Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <List>
                                {theories.map((item) =>
                                    <ListItem sx={{'paddingRight': '70px'}} key={item.id} secondaryAction={
                                        <>
                                            <IconButton edge="end" aria-label="edit">
                                                <Link to={item.id.toString()}><EditIcon /></Link>
                                            </IconButton>
                                            {item.category && <IconButton edge="end" aria-label="delete" onClick={removeClickHandler.bind(null, item.id)}>
                                                <DeleteIcon />
                                            </IconButton>}
                                        </>
                                    }>
                                        <ListItemText
                                            primary={item.title}
                                            secondary={item.category.title}
                                        />
                                    </ListItem>)
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default Theories;