import axios, { AxiosError, AxiosResponse } from 'axios';

export class QuizService{
    static async getAll(token: string){
        let result;
        try{
            result = await axios.get('api/quiz', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async getQuestion(token: string, id: number){
        let result;
        try{
            result = await axios.get('api/quiz/question/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async editQuestion(token: string, id:number, title: string, body: string, category_id: number, answers: {title: string}[], answer: number){
        let result;
        try{
            result = await axios.put('api/quiz/question/' + id, {
                title,
                body,
                category_id,
                answers,
                answer
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async insertQuestion(token: string, title: string, body: string, category_id: number, answers: {title: string}[], answer: number){
        let result;
        try{
            result = await axios.post('api/quiz/question', {
                title,
                body,
                category_id,
                answers,
                answer
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async removeQuestion(token: string, id: number){
        let result;
        try{
            result = await axios.delete('api/quiz/question', {
                data: {id},
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
}