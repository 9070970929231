import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/use-auth.hook';
import { UserStruct } from './users';
import { UserService } from '../services/user.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Stack, TextField, Button, Checkbox, FormGroup, FormLabel } from '@mui/material';

type FormType = {
    name: string,
    email: string,
    email_verif: boolean,
    password: string,
    admin: boolean
}

const EditUser: React.FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const [user, setUser] = useState<UserStruct>();
    const [form, setForm] = useState<FormType>();

    async function saveClickHandler(){
        if(user == null || form == null){
            return;
        }
        const result = await UserService.edit(auth.token, user.id, {
            email: user.email === form.email ? undefined : form.email,
            email_verif: user.email_verif === form.email_verif ? undefined : form.email_verif,
            name: user.name === form.name ? undefined : form.name,
            password: form.password === '' ? undefined : form.password,
            admin: user.admin === form.admin ? undefined : form.admin,
        });
        if(result.error){
            return;
        }
        navigate('/dashboard/users');
    }

    useEffect(() => {
        (async () => {
            if(!params.id || isNaN(+params.id)){
                navigate('/dashboard/users');
                return;
            }
            const user_result = await UserService.getOne(auth.token, +params.id);
            if(user_result.error){
                return;
            }
            setUser(user_result);
        })()
    }, []);

    useEffect(() => {
        if(user !== undefined){
            setForm(({
                email: user.email,
                email_verif: user.email_verif,
                name: user.name,
                password: '',
                admin: user.admin,
            }));
        }
        else{
            setForm(undefined);
        }
    }, [user]);

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        {
                            form !== undefined ? (
                                <Grid item xs={6}>
                                    <Stack spacing={1}>
                                        <TextField
                                            label="Էլ. հասցե"
                                            value={form.email}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(prev_form => ({
                                                    ...prev_form!,
                                                    email: event.target.value
                                                }))
                                            }}
                                            variant="standard"
                                            required
                                        />
                                        <FormGroup row={true} style={{alignItems: 'center'}}>
                                            <FormLabel>Էլ. հասցեն հաստատված է</FormLabel>
                                            <Checkbox
                                                checked={form.email_verif}
                                                onChange={e => {
                                                    setForm(prev_form => ({
                                                        ...prev_form!,
                                                        email_verif: e.target.checked
                                                    }))
                                                }}
                                            />
                                        </FormGroup>
                                        <TextField
                                            label="Անուն"
                                            value={form.name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(prev_form => ({
                                                    ...prev_form!,
                                                    name: event.target.value
                                                }))
                                            }}
                                            variant="standard"
                                            required
                                        />
                                        <TextField
                                            type="password"
                                            label="Գաղտնաբառ"
                                            value={form.password}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(prev_form => ({
                                                    ...prev_form!,
                                                    password: event.target.value
                                                }))
                                            }}
                                            variant="standard"
                                            required
                                        />
                                        <FormGroup row={true} style={{alignItems: 'center'}}>
                                            <FormLabel>Ադմին</FormLabel>
                                            <Checkbox
                                                checked={form.admin}
                                                onChange={e => {
                                                    setForm(prev_form => ({
                                                        ...prev_form!,
                                                        admin: e.target.checked
                                                    }))
                                                }}
                                            />
                                        </FormGroup>
                                        <Button variant="contained" onClick={saveClickHandler}>Պահպանել</Button>
                                    </Stack>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default EditUser;