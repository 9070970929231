import React, { useEffect, useState } from 'react';
import { useOutletContext, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { OutletType } from './dashboard';
import { CategoryService } from '../services/category.service';
import { QuizService } from '../services/quiz.service';
import useAuth from '../hooks/use-auth.hook';

interface QuestionStruct{
    id: number;
    title: string;
    body: string;
    answer: {
        id: number;
        title: string;
    },
    variants: {
        id: number;
        title: string;
    }[]
}

const Quizzes: React.FC = () => {
    const { setTitle } = useOutletContext<OutletType>();
    const [categories, setCategories] = useState<{title: string, id: number}[]>([]);
    const [question_title, setQuestionTitle] = useState('');
    const [question_body, setQuestionBody] = useState('');
    const [selected_category, setSelectedCategory] = useState('');
    const [questions, setQuestions] = useState<QuestionStruct[]>([]);
    const [answers, setAnswers] = useState<{title: string}[]>([{title: ''}, {title: ''}]);
    const [answer, setAnswer] = useState(0);
    const auth = useAuth();

    useEffect(() => {
        CategoryService.getAll(auth.token).then((res) => {
            setCategories(res);
        });
        QuizService.getAll(auth.token).then((res: {
            id: number,
            questions: QuestionStruct[]
        }[]) => {
            let arr: QuestionStruct[] = [];
            res.map((item) => 
                arr.push(...item.questions)
            )
            setQuestions(arr);
        });
    }, []);

    useEffect(() => {
        setTitle('Հարցեր');
    }, [setTitle]);

    async function removeClickHandler(id: number) {
        const res = await QuizService.removeQuestion(auth.token, id);
        if(res.success){
            setQuestions(questions.filter((item) => item.id !== id));
        }
    }

    function addAnswerHandler(){
        if(answers.length !== 6){
            setAnswers(answers.concat([{title: ''}]));
        }
    }

    function removeAnswerHandler(index: number){
        if(answers.length > 2){
            setAnswers(answers.filter((_item, i) => i !== index));
        }
    }

    async function addClickHandler(){
        if(
            question_title !== '' &&
            question_body !== '' &&
            selected_category !== '' &&
            answers.length >= 2 &&
            answers.every((item) => (item.title !== ''))
        ){
            const result = await QuizService.insertQuestion(auth.token, question_title, question_body, +selected_category, answers, answer);
            if(result.success){
                QuizService.getAll(auth.token).then((res: {
                    id: number,
                    questions: QuestionStruct[]
                }[]) => {
                    let arr: QuestionStruct[] = [];
                    res.map((item) => 
                        arr.push(...item.questions)
                    )
                    setQuestions(arr);
                });
            }
        }
    }

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField label="Հարցադրում"
                                    value={question_title} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        return setQuestionTitle(event.currentTarget.value);
                                    }}
                                    variant="standard"
                                    required
                                />
                                <TextField
                                    id="outlined-textarea"
                                    label="Հարց"
                                    placeholder="Հարց"
                                    value={question_body}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        return setQuestionBody(event.currentTarget.value);
                                    }}
                                    required
                                    multiline
                                />
                                <FormControl required fullWidth>
                                    <InputLabel id="category-select-label">Ընտրել Գլուխը</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="demo-simple-select"
                                        value={selected_category}
                                        label="Ընտրել Գլուխը"
                                        onChange={(event: SelectChangeEvent) => {
                                            return setSelectedCategory(event.target.value);
                                        }}
                                    >
                                        {
                                            categories.map((item) => {
                                                return <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <List>
                                    {answers.map((item, index, self) => ( 
                                        <ListItem sx={{'paddingRight': '110px'}} key={`answer_${index}`} secondaryAction={
                                            <>
                                                {
                                                    index === self.length - 1 && self.length !== 6 ? 
                                                    <IconButton edge="end" aria-label="edit" onClick={addAnswerHandler}>
                                                        <AddIcon />
                                                    </IconButton> : null
                                                }
                                                {
                                                    <Radio
                                                        checked={answer === index}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAnswer(+event.target.value)}
                                                        value={index}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                }
                                                {
                                                    self.length > 2 ?
                                                    <IconButton edge="end" aria-label="remove" onClick={removeAnswerHandler.bind(null, index)}>
                                                        <DeleteIcon />
                                                    </IconButton> : null
                                                }
                                            </>
                                        }>
                                            <TextField fullWidth label="Պատասխան"
                                                value={item.title} 
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    return setAnswers((prev) => {
                                                        const new_vals = [...prev];
                                                        new_vals[index].title = event.target.value;
                                                        return new_vals;
                                                    });
                                                }}
                                                variant="standard"
                                                required
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                                <Button variant="contained" onClick={addClickHandler}>Ավելացնել</Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <List>
                                {questions.map((item) =>
                                    <ListItem sx={{'paddingRight': '70px'}} key={`question_${item.id}`} secondaryAction={
                                        <>
                                            <IconButton edge="end" aria-label="edit">
                                                <Link to={item.id.toString()}><EditIcon /></Link>
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={removeClickHandler.bind(null, item.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    }>
                                        <ListItemText
                                            primary={item.title}
                                            secondary={item.body}
                                        />
                                    </ListItem>)
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default Quizzes;