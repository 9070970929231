import React, { useEffect, useState } from 'react';
import { UserService } from '../services/user.service';
import useAuth from '../hooks/use-auth.hook';
import { Link } from 'react-router-dom';
import { Box, Grid, Stack, TextField, Button, List, ListItem, ListItemText, IconButton, Checkbox, FormGroup, FormLabel } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, } from '@mui/icons-material';

export type UserStruct = {
    id: number,
    name: string,
    email: string,
    email_verif: boolean,
    password: string,
    admin: boolean
};

type FormType = {
    name: string,
    email: string,
    password: string,
    admin: boolean
}

const Users: React.FC = () => {
    const auth = useAuth();
    const [users, setUsers] = useState<UserStruct[]>([]);
    const [form, setForm] = useState<FormType>({
        name: '',
        email: '',
        password: '',
        admin: false
    });

    async function removeClickHandler(id: number){
        const res = await UserService.remove(auth.token, id);
        if(!res.error){
            setUsers(prev_users => prev_users.filter((item) => item.id !== id));
        }
    }

    async function addClickHandler(){
        if(form.email === '' || form.name === '' || form.password === ''){
            return;
        }
        const res = await UserService.create(auth.token, form.email, form.name, form.password, form.admin);
        if(res.error){
            return;
        }
        setUsers((prev) => prev.concat(res));
        setForm(({
            email: '',
            name: '',
            password: '',
            admin: false
        }));
        
    }

    useEffect(() => {
        (async () => {
            const users_result = await UserService.getAll(auth.token);
            if(users_result.error){
                return;
            }
            setUsers(users_result);
        })()
    }, []);

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField
                                    label="Էլ. հասցե"
                                    value={form.email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setForm(prev_form => ({
                                            ...prev_form,
                                            email: event.target.value
                                        }))
                                    }}
                                    variant="standard"
                                    required
                                />
                                <TextField
                                    label="Անուն"
                                    value={form.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setForm(prev_form => ({
                                            ...prev_form,
                                            name: event.target.value
                                        }))
                                    }}
                                    variant="standard"
                                    required
                                />
                                <TextField
                                    type="password"
                                    label="Գաղտնաբառ"
                                    value={form.password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setForm(prev_form => ({
                                            ...prev_form,
                                            password: event.target.value
                                        }))
                                    }}
                                    variant="standard"
                                    required
                                />
                                <FormGroup row={true} style={{alignItems: 'center'}}>
                                    <FormLabel>Ադմին</FormLabel>
                                    <Checkbox
                                        checked={form.admin}
                                        onChange={e => {
                                            setForm(prev_form => ({
                                                ...prev_form,
                                                admin: e.target.checked
                                            }))
                                        }}
                                    />
                                </FormGroup>
                                <Button variant="contained" onClick={addClickHandler}>Ավելացնել</Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <List>
                                {users.map((user) =>
                                    <ListItem sx={{'paddingRight': '70px'}} key={user.id} secondaryAction={
                                        <>
                                            <IconButton edge="end" aria-label="edit">
                                                <Link to={user.id.toString()}><EditIcon /></Link>
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={removeClickHandler.bind(null, user.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    }>
                                        <ListItemText
                                            primary={user.name}
                                            secondary={user.email}
                                        />
                                    </ListItem>)
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default Users;