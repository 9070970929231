import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import './index.css';
import App from './App';

// axios.defaults.baseURL = 'http://localhost:5001';
axios.defaults.baseURL = 'https://back.frontlineyouth.xyz';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </React.StrictMode>
);

