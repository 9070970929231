import axios, { AxiosError, AxiosResponse } from 'axios';

export class TheoryService{
    static async getAll(token: string){
        let result;
        try{
            result = await axios.get('api/theory', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async get(token: string, id: number){
        let result;
        try{
            result = await axios.get('api/theory/simple/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async edit(token: string, id: number, title: string, body: string, category_id: number){
        let result;
        try{
            result = await axios.put('api/theory/' + id, {
                title,
                body,
                category_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async insert(token: string, title: string, body: string, category_id: number){
        let result;
        try{
            result = await axios.post('api/theory', {
                title,
                body,
                category_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
    static async remove(token: string, id: number){
        let result;
        try{
            result = await axios.delete('api/theory', {
                data: {id},
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
}