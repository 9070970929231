import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


import { OutletType } from './dashboard';
import { QuizService } from '../services/quiz.service';
import { CategoryService } from '../services/category.service';
import useAuth from '../hooks/use-auth.hook';

interface QuestionStruct{
    id: number;
    title: string;
    body: string;
    answer: {
        id: number;
        title: string;
    },
    variants: {
        id: number;
        title: string;
    }[],
    quiz: {
        id: number;
        category: {
            id: number;
            title: string;
        }
    }
}

const Quiz: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { setTitle } = useOutletContext<OutletType>();
    const [categories, setCategories] = useState<{title: string, id: number}[]>([]);
    const [question_title, setQuestionTitle] = useState('');
    const [question_body, setQuestionBody] = useState('');
    const [selected_category, setSelectedCategory] = useState('');
    const [answers, setAnswers] = useState<{title: string}[]>([{title: ''}, {title: ''}]);
    const [answer, setAnswer] = useState(0);
    const auth = useAuth();

    useEffect(() => {
        CategoryService.getAll(auth.token).then((res) => {
            setCategories(res);
        });
        QuizService.getQuestion(auth.token, +id!).then((res: QuestionStruct) => {
            setQuestionTitle(res.title);
            setQuestionBody(res.body);
            setSelectedCategory(res.quiz.category.id.toString());
            setAnswers(res.variants.map((item, index) => {
                if(res.answer.id === item.id){
                    setAnswer(index);
                }
                return {title: item.title};
            }));
        });
    }, [id]);

    useEffect(() => {
        setTitle('Հարցեր');
    }, [setTitle]);

    function addAnswerHandler(){
        if(answers.length !== 6){
            setAnswers(answers.concat([{title: ''}]));
        }
    }

    function removeAnswerHandler(index: number){
        if(answers.length > 2){
            setAnswers(answers.filter((_item, i) => i !== index));
        }
    }

    async function saveQuestionHandler(){
        if(
            question_title !== '' &&
            question_body !== '' &&
            selected_category !== '' &&
            answers.length >= 2 &&
            answers.every((item) => (item.title !== ''))
        ){
            const result = await QuizService.editQuestion(auth.token, +id!, question_title, question_body, +selected_category, answers, answer);
            if(result.success){
                navigate(-1);
            }
        }
    }

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField label="Հարցադրում"
                                    value={question_title} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        return setQuestionTitle(event.currentTarget.value);
                                    }}
                                    variant="standard"
                                    required
                                />
                                <TextField
                                    id="outlined-textarea"
                                    label="Հարց"
                                    placeholder="Հարց"
                                    value={question_body}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        return setQuestionBody(event.currentTarget.value);
                                    }}
                                    required
                                    multiline
                                />
                                <FormControl required fullWidth>
                                    <InputLabel id="category-select-label">Ընտրել Գլուխը</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="demo-simple-select"
                                        value={selected_category}
                                        label="Ընտրել Գլուխը"
                                        onChange={(event: SelectChangeEvent) => {
                                            return setSelectedCategory(event.target.value);
                                        }}
                                    >
                                        {
                                            categories.map((item) => {
                                                return <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <List>
                                    {answers.map((item, index, self) => ( 
                                        <ListItem sx={{'paddingRight': '110px'}} key={`answer_${index}`} secondaryAction={
                                            <>
                                                {
                                                    index === self.length - 1 && self.length !== 6 ? 
                                                    <IconButton edge="end" aria-label="edit" onClick={addAnswerHandler}>
                                                        <AddIcon />
                                                    </IconButton> : null
                                                }
                                                {
                                                    <Radio
                                                        checked={answer === index}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAnswer(+event.target.value)}
                                                        value={index}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                }
                                                {
                                                    self.length > 2 ?
                                                    <IconButton edge="end" aria-label="remove" onClick={removeAnswerHandler.bind(null, index)}>
                                                        <DeleteIcon />
                                                    </IconButton> : null
                                                }
                                            </>
                                        }>
                                            <TextField fullWidth label="Պատասխան"
                                                value={item.title} 
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    return setAnswers((prev) => {
                                                        const new_vals = [...prev];
                                                        new_vals[index].title = event.target.value;
                                                        return new_vals;
                                                    });
                                                }}
                                                variant="standard"
                                                required
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                                <Button variant="contained" onClick={saveQuestionHandler}>Պահպանել</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default Quiz;