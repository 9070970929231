import axios, { AxiosError, AxiosResponse } from 'axios';

export class AuthService{
    static async login(email: string, password: string): Promise<{
        access_token: string,
        verified: true
    }>{
        const res = await axios.post('api/admin_login', {email, password});
        return res.data;
    }
    static async check(token: string): Promise<{success: boolean, verified: true } | any>{
        let res;
        try{ res = await axios.get('api/check', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });}
        catch(err: any){
            if(axios.isAxiosError(err)){
                res = (err as AxiosError).response as AxiosResponse;
            }
        };
        return res?.data;
    }
}