import axios, { AxiosError, AxiosResponse } from 'axios';

export class CategoryService{
    static async getAll(token: string){
        let result;
        try{
            result = await axios.get('api/theory/categories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }

    static async get(token: string, id: string){
        let result;
        try{
            result = await axios.get('api/theory/categories/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }

    static async addCategory(token: string, title: string){
        let result;
        try{
            result = await axios.post('api/theory/category', {
                title
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }

    static async edit(token: string, id: number, title: string){
        let result;
        try{
            result = await axios.put('api/theory/categories/' + id, {
                title
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }

    static async removeCategory(token: string, id: number){
        let result;
        try{
            result = await axios.delete('api/theory/category', {
                data: {id},
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        catch(err: any){
            if(axios.isAxiosError(err)){
                result = (err as AxiosError).response as AxiosResponse;
            }
        };
        return result?.data;
    }
}