import React, { createContext } from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.css';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Categories from './pages/categories';
import Theories from './pages/theories';
import Quizzes from './pages/quizzes';
import Quiz from './pages/quiz';
import Theory from './pages/theory';
import PrivacyPolicy from './pages/privacy-policy';
import AuthProvider from './components/auth-provider';
import ProtectedRoute from './components/protected-route';
import Category from './pages/category';
import Users from './pages/users';
import EditUser from './pages/edit-user';

export const AuthContext = createContext<{
    token: string,
    onLogin: (a: string, b: string, e: React.FormEvent) => Promise<void>,
    onLogout: () => void
}>({
    token: '',
    onLogin: async () => {},
    onLogout: () => {}
});

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
                <Route path="/dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute>}>
                    <Route path="" element={<></>} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/:id" element={<EditUser />} />
                    <Route path="categories" element={<Categories />} />
                    <Route path="categories/:id" element={<Category />} />
                    <Route path="theories" element={<Theories />} />
                    <Route path="theories/:id" element={<Theory />} />
                    <Route path="quizzes" element={<Quizzes />} />
                    <Route path="quizzes/:id" element={<Quiz />} />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;
