import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../components/header';

export interface OutletType{
    [key: string]: (prev: string) => void
}


const Dashboard: React.FC = () => {
    const [title, setTitle] = useState('Գլխավոր');

    return <div>
        <div className="wrapper"><Header title={title} /></div>
        <div>
            <Outlet context={{setTitle}} />
        </div>
    </div>;
}

export default Dashboard;